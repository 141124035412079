import { createRouter, createWebHistory } from 'vue-router';
import Home from '../views/Home.vue';
import store from '../store/index';

const routes = [
  {
    path: '/',
    redirect: '/login',
  },
  {
    path: '/dashboard',
    component: () => import('../views/layouts/Layouts'),
    meta: true,
    children: [
      {
        path: '/dashboard',
        name: 'dashboard',
        component: () => import(/* webpackChunkName: "dashboard" */ '../views/dashboard/Dashboard'),
        meta: {
          auth: true,
          breadCrumb: 'Dashboard',
        },
      },
      {
        path: '/appointments',
        name: 'Appointments',
        component: () => import(/* webpackChunkName: "patientlist" */'../views/appointments/AppointmentPage'),
        meta: {
          auth: true,
          breadCrumb: 'Appointments',
        },
      },
      {
        path: '/therapy-patients',
        name: 'Therapy Patients',
        component: () => import(/* webpackChunkName: "therapypatients" */'../views/therapies/Therapy'),
        meta: {
          auth: true,
          breadCrumb: 'Therapy Patients',
        },
      },
      {
        path: '/therapy/invoice',
        name: 'Therapy Invoice',
        component: () => import(/* webpackChunkName: "therapypatients" */'../views/invoice/Invoice'),
        meta: {
          auth: true,
          breadCrumb: 'Therapy Invoice',
        },
      },
      {
        path: '/invoice/:id',
        name: 'InvoiceDetail',
        component: () => import(/* webpackChunkName: "invoicedetails" */'../views/invoice/Invoice'),
        meta: {
          auth: true,
          breadCrumb: 'InvoiceDetail',
        },
      },
      // {
      //     path: '/appointment/:id',
      //     name: 'Appointments',
      //     component: () => import(/* webpackChunkName: "patientlist" */'../views/appointments/AppointmentPage'),
      //     meta: {
      //         auth: true,
      //         breadCrumb: 'Appointment',
      //     },
      // },
      {
        path: '/my/appointments',
        name: 'My Appointments',
        component: () => import(/* webpackChunkName: "patientlist" */'../views/appointments/MyAppointments'),
        meta: {
          auth: true,
          breadCrumb: 'My Appointments',
        },
      },
      {
        path: '/today/appointments',
        name: 'Today Appointments',
        component: () => import(/* webpackChunkName: "patientlist" */'../views/appointments/TodayAppointment'),
        meta: {
          auth: true,
          breadCrumb: 'Today Appointments',
        },
      },
      {
        path: '/brokers',
        name: 'Brokers',
        component: () => import(/* webpackChunkName: "brokerlist" */'../views/broker/Brokers'),
        meta: {
          auth: true,
          breadCrumb: 'Broker',
        },
      },
      {
        path: '/patients',
        name: 'Patients',
        component: () => import(/* webpackChunkName: "patientlist" */'../views/patient/Patients'),
        meta: {
          auth: true,
          breadCrumb: 'Patient',
        },
      },
      {
        path: '/my-patients',
        name: 'My Patients',
        component: () => import(/* webpackChunkName: "patientlist" */'../views/patient/MyPatient'),
        meta: {
          auth: true,
          breadCrumb: 'My Patients',
        },
      },
      {
        path: '/zero-club-foot-patients',
        name: 'Zero club foot patients',
        component: () => import(/* webpackChunkName: "patientlist" */'../views/patient/ZeroClubFootPatient'),
        meta: {
          auth: true,
          breadCrumb: 'Patient',
        },
      },
      {
        path: '/cancer-screening-patients',
        name: 'Cancer screening patients',
        component: () => import(/* webpackChunkName: "patientlist" */'../views/patient/CancerScreeningPatients'),
        meta: {
          auth: true,
          breadCrumb: 'Cancer screening patients',
        },
      },
      {
        path: '/cancer-screening-patients-register',
        name: 'Cancer screening patients register',
        component: () => import(/* webpackChunkName: "patientlist" */'../views/patient/CancerScreeningPatinetRegistration'),
        meta: {
          auth: true,
          breadCrumb: 'Cancer screening patients register',
        },
      },
      {
        path: '/zero-club-foot-patient/registration/:id',
        name: 'Zero club foot patients registration',
        component: () => import(/* webpackChunkName: "patientlist" */'../views/patient/ZeroClubFootRegistration'),
        meta: {
          auth: true,
          breadCrumb: 'Patient',
        },
      },
      {
        path: '/zero-club-foot-patient/update/:id',
        name: 'Zero club foot patients update',
        component: () => import(/* webpackChunkName: "patientlist" */'../views/patient/ZeroClubFootRegistration'),
        meta: {
          auth: true,
          breadCrumb: 'Patient',
        },
      },
      {
        path: '/cancer-patient/update/:id',
        name: 'Cancer patients update',
        component: () => import(/* webpackChunkName: "patientlist" */'../views/patient/CancerScreeningPatinetRegistration'),
        meta: {
          auth: true,
          breadCrumb: 'Cancer patients update',
        },
      },
      {
        path: '/zero-club-foot-patient/details/:id',
        name: 'Zero club foot patients details',
        component: () => import(/* webpackChunkName: "patientlist" */'../views/patient/ZeroClubFootDetails'),
        meta: {
          auth: true,
          breadCrumb: 'Patient',
        },
      },
      {
        path: '/cancer/patient/details/:id',
        name: 'Cancer patients details',
        component: () => import(/* webpackChunkName: "patientlist" */'../views/patient/CancerPatientDetails'),
        meta: {
          auth: true,
          breadCrumb: 'Cancer patients details',
        },
      },
      {
        path: '/patients/:id',
        name: 'Patients Details',
        component: () => import(/* webpackChunkName: "patientlist" */'../views/patient/PatientDetails'),
        meta: {
          auth: true,
          breadCrumb: 'Patient',
        },
      },
      {
        path: '/doctors',
        name: 'Doctors',
        component: () => import(/* webpackChunkName: "doctorlist" */ '../views/doctor/DoctorList'),

        meta: {
          auth: true,
          breadCrumb: 'Doctor',
        },
      },
      {
        path: '/outside/doctors',
        name: 'Outside Doctors',
        component: () => import(/* webpackChunkName: "outsideDoctorlist" */ '../views/doctor/OutsideDoctorList'),

        meta: {
          auth: true,
          breadCrumb: 'Outside Doctor',
        },
      },
      {
        path: '/medical/records',
        name: 'Medical record',
        component: () => import(/* webpackChunkName: "prescriptionlist" */ '../views/medical_record/PrescriptionList'),
        meta: {
          auth: true,
          breadCrumb: 'Presescription List',
        },
      },
      {
        path: '/prescription/preview/:id',
        name: 'prescription preview',
        component: () => import(/* webpackChunkName: "prescriptionlist" */ '../views/medical_record/PrescriptionPrint'),
        meta: {
          auth: true,
          breadCrumb: 'prescription preview',
        },
      },
      {
        path: '/statement',
        name: 'Transaction statement',
        component: () => import(/* webpackChunkName: "statement" */ '../views/statements/TransactionDetails'),
        meta: {
          auth: true,
          breadCrumb: 'Transaction statement',
        },
      },
      {
        path: '/invoices',
        name: 'Invoice',
        component: () => import(/* webpackChunkName: "statement" */ '../views/invoice/InvoiceList'),
        meta: {
          auth: true,
          breadCrumb: 'Invoice',
        },
      },
      {
        path: '/notifications',
        name: 'Notifications',
        component: () => import(/* webpackChunkName: "notification" */ '../views/notification/NotificationList'),
        meta: {
          auth: true,
          breadCrumb: 'Notifications',
        },
      },
      {
        path: '/doctor/search',
        name: 'Doctor search',
        component: () => import(/* webpackChunkName: "search" */ '../views/doctor/Search'),
        meta: {
          auth: true,
          breadCrumb: 'Doctor search',
        },
      },
      {
        path: '/doctor/:id',
        name: 'Doctor',
        component: () => import(/* webpackChunkName: "doctordetails" */ '../views/doctor/DoctorDetails'),
        meta: {
          auth: true,
          breadCrumb: 'Doctor',
        },
      },
      {
        path: '/setting',
        name: 'Setting',
        component: () => import(/* webpackChunkName: "setting" */ '../views/setting/Setting'),
        meta: {
          auth: true,
          breadCrumb: 'Setting',
        },
      },
      {
        path: '/create/package/invoice',
        name: 'package invoice',
        component: () => import(/* webpackChunkName: "packageinvoice" */ '../views/invoice/PackageInvoice'),
        meta: {
          auth: true,
          breadCrumb: 'Package invoice',
        },
      },
      {
        path: '/call/logs',
        name: 'Call log',
        component: () => import(/* webpackChunkName: "calllogs" */ '../views/crm/CallLogList'),
        meta: {
          auth: true,
          breadCrumb: 'Call log',
        },
      },
      {
        path: '/invoices/:uid/installments',
        name: 'installments history',
        component: () => import(/* webpackChunkName: "installmentshistory" */ '../views/invoice/InstallmentHistory'),
        meta: {
          auth: true,
          breadCrumb: 'installments history',
        },
      },
      {
        path: '/invoices/:uid/services',
        name: 'services history',
        component: () => import(/* webpackChunkName: "invoiceservices" */ '../views/invoice/ServiceHistory'),
        meta: {
          auth: true,
          breadCrumb: 'services history',
        },
      },
      {
        path: '/feedbacks',
        name: 'Feedbacks',
        component: () => import(/* webpackChunkName: "feedbacks" */ '../views/feedback/FeedbackList'),
        meta: {
          auth: true,
          breadCrumb: 'Feedbacks',
        },
      },
      {
        path: '/welfare-fund',
        name: 'Welfare fund',
        component: () => import(/* webpackChunkName: "welfarefunds" */ '../views/welfare/WelfareFund'),
        meta: {
          auth: true,
          breadCrumb: 'Welfare fund',
        },
      },
      {
        path: '/welfare-application',
        name: 'Welfare application',
        component: () => import(/* webpackChunkName: "welfareapplications" */ '../views/welfare/Application'),
        meta: {
          auth: true,
          breadCrumb: 'Welfare application',
        },
      },
      {
        path: '/welfare/application/create',
        name: 'Welfare application create',
        component: () => import(/* webpackChunkName: "welfareapplicationscreate" */ '../views/welfare/ApplicationCreateUpdate'),
        meta: {
          auth: true,
          breadCrumb: 'Welfare application create',
        },
      },
      {
        path: '/welfare/application/update/:id',
        name: 'Welfare application  update',
        component: () => import(/* webpackChunkName: "welfareapplicationsupdate" */ '../views/welfare/ApplicationCreateUpdate'),
        meta: {
          auth: true,
          breadCrumb: 'Welfare application  update',
        },
      },
      {
        path: '/welfare/application/review/:id',
        name: 'Welfare application review',
        component: () => import(/* webpackChunkName: "welfareapplicationsreview" */ '../views/welfare/ApplicationReview'),
        meta: {
          auth: true,
          breadCrumb: 'Welfare application review',
        },
      },
      {
        path: '/zcf/service/plans',
        name: 'ZCF Service Plan',
        component: () => import(/* webpackChunkName: "ServicePlan" */ '../views/zcf_service_plan/ServicePlan'),
        meta: {
          auth: true,
          breadCrumb: 'ZCF Service Plan',
        },
      },
      {
        path: '/zcf/service/plans/create',
        name: 'ZCF Service Plan Create',
        component: () => import(/* webpackChunkName: "ServicePlanCreate" */ '../views/zcf_service_plan/ServicePlanCreate'),
        meta: {
          auth: true,
          breadCrumb: 'ZCF Service Plan Create',
        },
      },
      {
        path: '/referral',
        name: 'Doctor/Employee Referral',
        component: () => import(/* webpackChunkName: "referral" */ '../views/referral/Referral'),
        meta: {
          auth: true,
          breadCrumb: 'Doctor/Employee Referral',
        },
      },
      {
        path: '/disbursements',
        name: 'Disbursements',
        component: () => import(/* webpackChunkName: "disbursements" */ '../views/disbursements/Disbursement'),
        meta: {
          auth: true,
          breadCrumb: 'Disbursements',
        },
      },
      {
        path: '/disbursements/create',
        name: 'Disbursement Create',
        component: () => import(/* webpackChunkName: "disbursementscreate" */ '../views/disbursements/Create'),
        meta: {
          auth: true,
          breadCrumb: 'Disbursement Create',
        },
      },
      {
        path: '/referrals/disbursements/:uid',
        name: 'Referral Disbursement Details',
        component: () => import(/* webpackChunkName: "disbursementsdetails" */ '../views/disbursements/View'),
        meta: {
          auth: true,
          breadCrumb: 'Referral Disbursement Details',
        },
      },

    ],
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '../views/auth/Login.vue'),
  },
  {
    path: '/tv/:id',
    name: 'TV',
    component: () => import(/* webpackChunkName: "login" */ '../views/tvScreen/TvScreen'),
  },
  // {
  //     path: "*",
  //     redirect: "/404"
  // },
  // {
  //     path: "/404",
  //     redirect: "/404",
  //     component: () => import("../views/Error"),
  // }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.auth)) {
    // console.log('record', record.meta.auth)
    if (store.state.auth.user) {
      const api_token = localStorage.getItem('api_token');
      if (api_token) {
        if (!store.state.auth.loggedIn) {
          next({
            name: 'login',
          });
        } else {
          next();
        }
      } else {
        next({
          name: 'login',
        });
      }
    } else {
      next({
        name: 'login',
      });
    }
  } else {
    next();
  }
});
export default router;
