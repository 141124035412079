import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters('auth', ['usrPerms']),
  },
  methods: {
    checkUsrPermissions(reqPermission) {
      if (!reqPermission) return true;
      let allPerms = this.usrPerms;
      if (!allPerms) {
        allPerms = [];
      }
      return allPerms.includes(reqPermission);
    },
  },
};
