import axios from 'axios';

const user = JSON.parse(localStorage.getItem('user'));
const auth = {
  namespaced: true,
  state: {
    user: {},
    loggedIn: false,
    usrPerms: [],
    institute: {},
  },
  actions: {
    login({
      dispatch,
      commit,
    }, form) {
      commit('loginRequest', form.userid);
      const formD = new FormData();
      formD.append('userid', `+88${form.userid}`);
      formD.append('password', form.password);
      const url = `${process.env.VUE_APP_API_URL}/api/v1/occ-login`;
      return axios.post(url, formD)
        .then((res) => {
          commit('loginSuccess', res.data.user);
          return res;
        })
        .catch((err) => err);
    },
    logout({ commit }) {
      const url = '/api/v1/user/logout';
      return axios.post(url)
        .then((res) => {
          localStorage.removeItem('api_token');
          commit('logout');
          return res;
        })
        .catch((err) => err);
    },
    getUserPermissions({ commit }) {
      const url = '/api/v1/user/permission/list';
      return axios.get(url)
        .then((res) => {
          commit('SET_USER_PERMISSIONS', res.data.data);
          return res;
        })
        .catch((err) => err);
    },
    getInstitute({ commit }) {
      const url = '/api/v2/user/institute';
      return axios.get(url)
        .then((res) => {
          commit('SET_USER_INSTITUTE', res.data.data);
          return res;
        })
        .catch((err) => err);
    },
  },
  mutations: {
    loginRequest(state, user) {
      state.status = { loggingIn: true };
      state.user = user;
    },
    loginSuccess(state, user) {
      state.loggedIn = true;
      state.user = user;
    },
    loginFailure(state) {
      state.status = {};
      state.user = null;
    },
    logout(state) {
      state.status = {};
      state.loggedIn = false;
      state.user = null;
    },
    SET_USER_PERMISSIONS(state, permissions) {
      state.usrPerms = permissions;
    },
    SET_USER_INSTITUTE(state, institute) {
      state.institute = institute;
    },
  },
  getters: {
    authUser: (state) => state.user,
    userInstitute: (state) => state.institute,
    usrPerms: (state) => state.usrPerms,
  },
};

export default auth;
