import { createApp } from 'vue';
import ElementPlus from 'element-plus';
import { library } from '@fortawesome/fontawesome-svg-core';
import * as ElementPlusIconsVue from '@element-plus/icons-vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import {
  faUserSecret,
  faUserDoctor,
  faBedPulse,
  faCalendarCheck,
  faBone,
  faBaby,
  faPhoneVolume,
  faEdit,
  faTrash,
  faPrint,
  faLocationDot,
  faPhone,
  faEnvelope,
  faFileInvoice,
  faMoneyBill,
  faCalendarDay,
  faRotateRight,
} from '@fortawesome/free-solid-svg-icons';
import App from './App.vue';
import router from './router';
import store from './store';
import './axiosinstance';
import './assets/style.css';
import './assets/responsive.css';
import 'element-plus/dist/index.css';
import titleMixin from './mixins/title';
import globalMixin from './mixins/globalMixin';
import './components/components';
// axios.defaults.withCredentials = true;
/* import the fontawesome core */

/* import font awesome icon component */

/* import specific icons */

import VueHtmlToPaper from './VueHtmlToPaper';

const axios = require('axios');

/* add icons to the library */
library.add(faUserSecret, faUserDoctor, faBedPulse, faCalendarCheck, faBone, faBaby, faPhoneVolume, faEdit, faTrash, faPrint, faLocationDot, faPhone, faEnvelope, faFileInvoice, faMoneyBill, faCalendarDay, faRotateRight);

const app = createApp(App);

app.use(VueHtmlToPaper);

app.mixin(titleMixin);
app.mixin(globalMixin);
app.use(ElementPlus);
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component);
}
app.use(store)
  .use(router)
  .component('font-awesome-icon', FontAwesomeIcon)
  .mount('#app');
